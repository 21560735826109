<template>
  <div ref="videoWrapper">
    <div
      class="size-full transition-opacity opacity-0"
      :class="{ 'opacity-100': videoLoaded }"
      :aria-hidden="props.videoAriaHidden"
      :aria-label="$texts('accessibility.video_player', 'Videoplayer')"
    >
      <video
        ref="videoPlayer"
        playsinline
        class="size-full top-0 left-0 object-cover absolute"
        preload="none"
        @click="togglePlayPause"
      />
    </div>

    <button class="size-full absolute top-0 left-0" @click="togglePlayPause">
      <span class="sr-only">
        {{ videoButtonText }}
      </span>
    </button>
    <div
      :class="{ paused: isPaused }"
      class="video-player__control absolute right-7 bottom-7"
    />
  </div>
</template>

<script setup lang="ts">
import type Hls from 'hls.js'
import type { ErrorData } from 'hls.js'
const { $texts } = useNuxtApp()

interface VideoPlayerProps {
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  sources?: {
    src: string
    type?: string
  }[]
  muted?: boolean
  videoAriaHidden?: boolean
}

const props = withDefaults(defineProps<VideoPlayerProps>(), {
  autoplay: false,
  controls: true,
  loop: false,
  sources: undefined,
  muted: false,
  videoAriaHidden: false,
})

const videoWrapper = ref<HTMLElement | null>(null)
const videoPlayer = ref<HTMLVideoElement | null>(null)
const hls = ref<Hls | null>(null)
const isPaused = ref(!props.autoplay)
const videoLoaded = ref(false) // Used to show the video when it's loaded

const videoButtonText = computed(() => {
  return isPaused.value
    ? $texts(
        'accessibility.video_is_paused',
        'Das Video wird pausiert. Drücken Sie zum Abspielen.',
      )
    : $texts(
        'accessibility.video_is_running',
        'Das Video wird abgespielt. Drücken Sie zum Anhalten.',
      )
})

const togglePlayPause = () => {
  if (videoPlayer.value) {
    if (videoPlayer.value.paused) {
      videoPlayer.value.play()
      isPaused.value = false
    } else {
      videoPlayer.value.pause()
      isPaused.value = true
    }
  }
}

// Initialize HLS only when video comes into view
const loadHls = async () => {
  const { default: Hls } = await import('hls.js')

  if (
    Hls.isSupported() &&
    videoPlayer.value &&
    props.sources &&
    props.sources[0].src
  ) {
    hls.value = new Hls({
      capLevelToPlayerSize: true,
      maxDevicePixelRatio: 2,
      maxBufferLength: 6,
      maxBufferSize: 0, // this is overridden by maxMaxBufferLength
    })
    hls.value.loadSource(props.sources[0].src)
    hls.value.attachMedia(videoPlayer.value)

    hls.value?.on(Hls.Events.MANIFEST_PARSED, () => {
      videoLoaded.value = true // Set loaded flag for fade-in
      // in theory not exposed by hls.js, but it works
      const capLevelController = (hls.value as any)?.capLevelController
      const levels = hls.value?.levels
      if (!capLevelController || !levels) {
        console.error('Hls – capLevelController or levels not available')
        return
      }

      const maxLevel = capLevelController.getMaxLevel(levels.length - 1)

      if (hls.value && maxLevel !== -1) {
        hls.value.startLevel = maxLevel
      } else {
        console.error('Hls – maxLevel not available')
      }
    })

    hls.value?.on(Hls.Events.ERROR, function (_, data: ErrorData) {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('Hls – fatal media error encountered, try to recover')
            hls.value?.recoverMediaError()
            break
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error('Hls – fatal network error encountered', data)
            // All retries and media options have been exhausted.
            // Immediately trying to restart loading could cause loop loading.
            // Consider modifying loading policies to best fit your asset and network
            // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
            break
          default:
            console.error('Hls – cannot recover from error', data)
            // cannot recover
            hls.value?.destroy()
            break
        }
      }
    })
  } else if (
    videoPlayer.value &&
    videoPlayer.value.canPlayType('application/vnd.apple.mpegurl')
  ) {
    videoPlayer.value.src = props.sources![0].src
    videoLoaded.value = true // Set loaded flag for fade-in
  }

  if (videoPlayer.value) {
    videoPlayer.value.controls = props.controls
    videoPlayer.value.autoplay = props.autoplay
    videoPlayer.value.loop = props.loop
    videoPlayer.value.muted = props.muted
    videoPlayer.value.play()
    isPaused.value = false
  }
}

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      observer.disconnect()
      loadHls() // Load HLS when the video comes into view
    }
  })
  observer.observe(videoWrapper.value!)
})

onBeforeUnmount(() => {
  hls.value?.destroy()
})

defineOptions({
  name: 'MediaVideoVideoPlayer',
})
</script>

<style lang="postcss">
.video-player__control {
  --base-size: 30px;

  @screen lg {
    --base-size: 40px;
  }

  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: calc(var(--base-size) * 1);
  border-color: transparent transparent transparent theme('colors.stone.500');
  transition: 100ms all ease;
  cursor: pointer;
  pointer-events: none;

  /* play state */
  border-style: double;
  border-width: 0px 0 0px calc(var(--base-size) * 0.866);

  &.paused {
    border-style: solid;
    border-width: calc(var(--base-size) * 0.5) 0 calc(var(--base-size) * 0.5)
      calc(var(--base-size) * 0.866);
  }

  &:hover {
    border-color: transparent transparent transparent theme('colors.stone.700');
  }
}
</style>
